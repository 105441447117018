import React, { Component } from 'react';
import logo from '../assets/logo.png';
import h1 from '../assets/h1.png';
import h2 from '../assets/h2.png';
import Countdown from 'react-countdown';
import '../counter.css';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

class Home extends Component {

    state = {
        stateNow: Date.now()
    }

    render() {

        return (

            <div class="stakeMainHome">

                <img class="homeLogo" src={logo} />

                {this.state.stateNow >= 1665100800000?

                (<div class="homeBtn">

                    <button onClick={() => window.location.href = '/exopetstake'}>EXO PET</button>
                    <button onClick={() => window.location.href = '/exowarrior'}>EXO WARRIOR</button>

                </div>):


                (
                <div class="countdown">
                    <Countdown
                        date={1665100800000}
                        renderer={renderer}
                    />
                </div>
                
                )}

                <div class="homeImages">
                    <div class="homeImages1"><img class="right" src={h1} /></div>
                    <div class="homeImages2"><img class="right" src={h2} /></div>
                </div>

           
            </div>)
    }
}

export default Home;